import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useWeb3 } from "../../web3/Web3Context";
import Title from "../../Components/Common/Title/Title";
import squareIcon from "../../assets/images/pico-card-collection/square-cards-icon.png";
import triangleIcon from "../../assets/images/pico-card-collection/triangle-cards-icon.png";
import circleIcon from "../../assets/images/pico-card-collection/circle-cards-icon.png";
import rectangleIcon from "../../assets/images/pico-card-collection/rectangle-cards-icon.png";
import character from "../../assets/images/pico-card-collection/character-icon.png";
import weapon from "../../assets/images/pico-card-collection/weapon-icon.png";
import shield from "../../assets/images/pico-card-collection/shield-icon.png";
import {
  grabberContract,
  cardContract,
  pico1v1Contract,
  viewerContract,
  storageContract,
} from "../../web3/contracts";
import { ethers } from "ethers";
import "./YourCards.scss";
import { notify } from "../../utils/customToast";
import cardMetadata from "../../web3/cardMetadata.json";
import Modal from "../../Components/Common/Modal/Modal";
import PrimaryButton from "../../Components/Common/UI/PrimaryButton/PrimaryButton";
import CloseButton from "../../Components/Common/UI/PrimaryButton/CloseButton";
import t1 from "../../assets/images/badges/T1.png";
import t2 from "../../assets/images/badges/T2.png";
import t3 from "../../assets/images/badges/T3.png";
import ProgressDemo from "../../Components/Common/ProgressBar/ProgressBar";
import ToggleSwitch from "../../Components/Common/UI/ToggleSwitch/ToggleSwitch";
import { Link } from "react-router-dom";
import DeckBuilderInfo from "./Components/DeckBuilderInfo/DeckBuilderInfo";
import BorderStyle from "../../Components/Common/BorderStyle/BorderStyle";
import Loader from "../../Components/Common/Loader/Loader";
import btnBg from "../../assets/images/deckBuilder/btnBackground.png";
type Props = {};
type Card = {
  id: number;
  cardType: number;
  image: string;
  name: string;
  rarity: number;
  cattype: number;
  strength: number;
};

const YourCards: React.FC<Props> = () => {
  const DECK_SIZE = 13; // Minimum number of cards per complete deck
  const { myAddress, isConnected } = useWeb3();
  const [selectedType, setSelectedType] = useState<number>(-1);
  const [selectedRarity, setSelectedRarity] = useState<number>(-1);

  const [allData, setAllData] = useState<Card[]>([]);
  const [storageallData, setstorageAllData] = useState<Card[]>([]);

  const [filteredData, setFilteredData] = useState<Card[]>([]);
  const [storagefilteredData, setstorageFilteredData] = useState<Card[]>([]);

  const [total, setTotal] = useState<number>(0);
  const [storagetotal, setstorageTotal] = useState<number>(0);

  const [mainLoader, setMainLoader] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<Card | null>(null);
  const [transferCard, setTransferCard] = useState<boolean>(false);
  const [toAddress, setToAddress] = useState<string>("");
  const [circulatingSupply, setCirculatingSupply] = useState<string>("");
  const [burnedSupply, setBurnedSupply] = useState<string>("");
  const [tier, settier] = useState<number>(11);
  const [avgstrength, setavgstrength] = useState<number>(0);

  const [cardAccess, setCardAccess] = useState<boolean>(false);
  const [isApprovedForAll, setIsApprovedForAll] = useState<boolean>(false);

  // Selected cards
  const [selectedCards, setSelectedCards] = useState<Card[]>([]);
  const [isDeckActive, setIsDeckActive] = useState<boolean>(false);

  //Wallet-Storage
  const [isStorageActive, setIsStorageActive] = useState<boolean>(false);
  const [costBulk, setcostBulk] = useState<number>(0);

  const [scrapModalOpen, setScrapModalOpen] = useState<boolean>(false);
  const [isExternalTransferModalOpen, setIsExternalTransferModalOpen] =
    useState<any>(false);

  const toggleCardSelection = (card: Card) => {
    setSelectedCards((prevSelected) => {
      if (prevSelected.some((selectedCard) => selectedCard.id === card.id)) {
        // If the card is already selected, remove it
        return prevSelected.filter(
          (selectedCard) => selectedCard.id !== card.id
        );
      } else if (prevSelected.length < 80) {
        // If less than 5 cards are selected, add the new card
        return [...prevSelected, card];
      }
      // If 5 cards are already selected, do nothing
      return prevSelected;
    });
  };

  const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // Take a maximum of 5 cards or the total number of cards if less than 5
      const newCards = allData.slice(0, Math.min(80, allData.length));
      setSelectedCards(newCards);
    } else {
      // Clear selected cards if the checkbox is unchecked
      setSelectedCards([]);
    }
  };
  const filterCards = (rarity: number, type: number) => {
    let filtered = [...allData];

    if (rarity !== -1) {
      filtered = filtered.filter((card) => card.rarity === rarity);
    }

    if (type !== -1) {
      filtered = filtered.filter((card) => card.cattype === type);
    }

    setFilteredData(filtered);
  };

  //storage version
  const storagefilterCards = (rarity: number, type: number) => {
    let storagefiltered = [...storageallData];

    if (rarity !== -1) {
      storagefiltered = storagefiltered.filter(
        (card) => card.rarity === rarity
      );
    }

    if (type !== -1) {
      storagefiltered = storagefiltered.filter((card) => card.cattype === type);
    }

    setstorageFilteredData(storagefiltered);
  };

  const handleRarityClick = (rarity: number) => {
    let newRarity = selectedRarity === rarity ? -1 : rarity;

    if (newRarity === 3) {
      setSelectedType(-1);
    }

    setSelectedRarity(newRarity);

    if (isStorageActive) {
      storagefilterCards(newRarity, selectedType);
    } else {
      filterCards(newRarity, selectedType);
    }
  };

  const handleTypeClick = (type: number) => {
    let newType = selectedType === type ? -1 : type;

    if (newType !== -1 && selectedRarity === 3) {
      setSelectedRarity(-1);
      if (isStorageActive) {
        storagefilterCards(-1, newType);
      } else {
        filterCards(-1, newType);
      }
    } else {
      setSelectedType(newType);

      if (isStorageActive) {
        storagefilterCards(selectedRarity, newType);
      } else {
        filterCards(selectedRarity, newType);
      }
    }
  };

  const getAllCards = useCallback(async () => {
    try {
      setMainLoader(true);
      let tokenIds: Card[] = [];
      let balance = await cardContract?.balanceOf(myAddress);
      if (balance && Number(balance) > 0) {
        setTotal(Number(balance));
        await getCardsBatch(tokenIds, 0, Number(balance));
        setAllData(tokenIds);
        setFilteredData([...tokenIds]);
      }
      setMainLoader(false);

      const myavg = await viewerContract?.getDeckStrength(myAddress);
      const mytier = await viewerContract?.getDeckTier(myAddress);
      settier(Number(mytier));
      setavgstrength(Number(myavg));
    } catch (e) {
      //   notify("Failed to fetch balance", "error");
      console.error(e);
      setMainLoader(false);
    }
  }, [myAddress]);

  //storage
  const storageGetAllCards = useCallback(async () => {
    try {
      setMainLoader(true);
      let tokenIds: Card[] = [];
      let balance = await storageContract?.getStoredLength(myAddress);
      if (balance && Number(balance) > 0) {
        setstorageTotal(Number(balance));
        await getCardsBatch(tokenIds, 0, Number(balance));
        setstorageAllData(tokenIds);
        setstorageFilteredData(tokenIds);
      }
      setMainLoader(false);
    } catch (e) {
      //   notify("Failed to fetch balance", "error");
      console.error(e);
      setMainLoader(false);
    }
  }, [myAddress, isStorageActive]);

  const getCardsBatch = async (
    tokenIds: Card[],
    from: number,
    maxLength: number
  ) => {
    try {
      let cardBatch;
      if (isStorageActive) {
        cardBatch = await storageContract?.getStoredCardsBatch(myAddress, from);
      } else {
        cardBatch = await grabberContract?.getCardsBatch(myAddress, from);
      }

      for (let i = 0; i < cardBatch[0].length; i++) {
        const cardType = Number(cardBatch[1][i]);
        const metadata = cardMetadata.find((meta) => meta.id === cardType);
        if (metadata) {
          const isMutant = cardBatch[2][i];
          tokenIds.push({
            id: Number(cardBatch[0][i]),
            cardType: cardType,
            image: isMutant
              ? `mutantsvgs/${cardType}.svg`
              : `cardsvgs/${cardType}.svg`,
            name: metadata.name,
            rarity: metadata.rarity,
            cattype: metadata.type,
            strength: metadata.strength,
          });
        }
      }

      if (from + 40 < maxLength) {
        await getCardsBatch(tokenIds, from + 40, maxLength);
      }
    } catch (e) {
      console.error(e);
      if (from + 40 < maxLength) {
        await getCardsBatch(tokenIds, from + 40, maxLength);
      }
    }
  };

  const onCardClick = async (item: Card) => {
    setSelectedCard(item);
    setOpenModal(true);
    let data: any = await cardContract?.categories(item.cardType);
    let circulatingSupplyData =
      Number(data[1]) - Number(data[0]) - Number(data[2]);
    setCirculatingSupply(circulatingSupplyData.toString());
    setBurnedSupply(data[2].toString());
  };

  const handleToAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setToAddress(event.target.value.trim());
  };

  const transferNft = async (tokenId: number) => {
    if (!toAddress || !tokenId) return;
    try {
      setMainLoader(true);
      const transaction = await cardContract?.transferFrom(
        myAddress,
        toAddress,
        tokenId
      );
      await transaction.wait();
      notify("Transfer of card is successful.", "success");
      window.location.reload();
    } catch (e) {
      console.error("Error transferring NFT:", e);
      notify("Failed to transfer card", "error");
      setMainLoader(false);
    }
  };

  useEffect(() => {
    if (isStorageActive) {
      storageGetAllCards();
    } else {
      getAllCards();
    }
    checkApprovalForAll();
  }, [isStorageActive]);

  useEffect(() => {
    if (isConnected && myAddress) {
      if (isStorageActive) {
        storageGetAllCards();
      } else {
        getAllCards();
      }
      checkApprovalForAll();
    }
  }, [isConnected, myAddress, getAllCards, isStorageActive]);

  const handleToggleChange = async (checked: boolean) => {
    if (checked) {
      setIsDeckActive(checked);
      //   setSelectedCards(cardsList);
    } else {
      setSelectedCards([]);
      setIsDeckActive(checked);
    }
  };

  const handleWalletToggle = async (checked: boolean) => {
    setIsStorageActive(checked);
    setSelectedRarity(-1);
    setSelectedType(-1);
    setSelectedCards([]);
  };

  const toggleCardAccess = async (checked: boolean) => {
    setCardAccess(checked);
    if (!cardContract || !storageContract || !myAddress) return;
    try {
      const tx = await cardContract.setApprovalForAll(
        storageContract.address,
        checked
      );
      await tx.wait();
      setIsApprovedForAll(checked);
      console.log(`Card access ${checked ? "approved" : "revoked"}`);
    } catch (error) {
      console.error("Error setting card access approval:", error);
    }
  };

  const checkApprovalForAll = useCallback(async () => {
    if (!cardContract || !storageContract || !myAddress) return;
    try {
      const isApproved = await cardContract.isApprovedForAll(
        myAddress,
        storageContract.address
      );
      setIsApprovedForAll(isApproved);
      setCardAccess(isApproved);
      const cost = await storageContract?.transferFee();
      setcostBulk(Number(cost));
    } catch (error) {
      console.error("Error checking approval status:", error);
    }
  }, [cardContract, storageContract, myAddress]);

  const bulkStoreSelected = async () => {
    setMainLoader(true);
    try {
      const cardIds = selectedCards.map((card) => Number(card.id));
      const overrides = {
        gasLimit: ethers.utils.hexlify(8000000),
        value: ethers.utils.parseUnits(costBulk.toString(), 18),
      };

      const tx = await storageContract?.transferBulkToStorage(
        cardIds,
        overrides
      );

      //   const creditBala = await  storageContract?.creditBalances(myAddress)
      await tx.wait();

      notify("Sent to Storage!", "success");
    } catch (error) {
      console.error("Transaction failed:", error);
      notify("Failed to send to storage", "error");
    } finally {
      setMainLoader(false);
      window.location.reload();
    }
  };

  const bulkTransferSelected = async () => {
    setMainLoader(true);
    try {
      const cardIds = selectedCards.map((card) => Number(card.id));
      const overrides = {
        gasLimit: ethers.utils.hexlify(8000000),
        value: ethers.utils.parseUnits(costBulk.toString(), 18),
      };
      const address = "";
      //   transferBulkToAddressFromWallet(address _destination, uint[] memory _cards, bool useIntermediateStorage)
      const tx = await storageContract?.transferBulkToAddressFromWallet(
        toAddress,
        cardIds,
        false,
        overrides
      );
      await tx.wait();

      notify("Sent to Address!", "success");
    } catch (error) {
      console.error("Transaction failed:", error);
      notify("Failed to send to storage", "error");
    } finally {
      setMainLoader(false);
      window.location.reload();
    }
  };

  const bulkScrapSelected = async () => {
    setMainLoader(true);
    try {
      const cardIds = selectedCards.map((card) => Number(card.id));
      const overrides = {
        gasLimit: ethers.utils.hexlify(8000000),
        value: ethers.utils.parseUnits(costBulk.toString(), 18),
      };
      const tx = await storageContract?.recycleCards(cardIds, overrides);
      await tx.wait();

      notify("Sent to Address!", "success");
    } catch (error) {
      console.error("Transaction failed:", error);
      notify("Failed to send to storage", "error");
    } finally {
      setMainLoader(false);
      window.location.reload();
    }
  };

  const bulkRetrieveSelected = async () => {
    setMainLoader(true);
    try {
      const cardIds: number[] = selectedCards.map((card) => Number(card.id));
      const overrides = {
        gasLimit: ethers.utils.hexlify(8000000),
        value: ethers.utils.parseUnits(costBulk.toString(), 18),
      };

      const tx = await storageContract?.transferBulkFromStorage(
        myAddress,
        cardIds,
        overrides
      );
      await tx.wait();
      notify("Retrieved Successfully!", "success");
    } catch (error) {
      console.error("Transaction failed:", error);
      notify("Failed to send to storage", "error");
    } finally {
      setMainLoader(false);
      window.location.reload();
    }
  };

  //aegix test logs

  console.log("selectedCards", selectedCards);
  const cardsList = useMemo(() => {
    if (isStorageActive) {
      return [...storageallData];
    } else {
      return [...allData];
    }
  }, [isStorageActive, storagefilteredData, filteredData]);

  const cardswithActiveFilters = useMemo(() => {
    if (isStorageActive) {
      return storagefilteredData;
    } else {
      return filteredData;
    }
  }, [isStorageActive, storagefilteredData, filteredData]);

  const selectedCardIds = useMemo(
    () => new Set(selectedCards.map((card) => card.id)),
    [selectedCards]
  );
  const userPlayingCards = useMemo(() => {
    return cardsList.filter((card) => !selectedCardIds.has(card.id));
  }, [cardsList, selectedCards, isStorageActive]);

  const groupedByType = useMemo(() => {
    return userPlayingCards.reduce((acc: any, card: any) => {
      if (!acc[card.cattype]) {
        acc[card.cattype] = [];
      }
      acc[card.cattype].push(card);
      return acc;
    }, {});
  }, [userPlayingCards]);

  // Count total cards by cattype and rarity
  //   const cardCounts = useMemo(() => {
  //     const counts: any = {
  //       cattype0_rarity0: 0,
  //       cattype0_rarity1: 0,
  //       cattype0_rarity2: 0,
  //       cattype1_rarity0: 0,
  //       cattype1_rarity1: 0,
  //       cattype1_rarity2: 0,
  //       cattype2_rarity0: 0,
  //       cattype2_rarity1: 0,
  //       cattype2_rarity2: 0,
  //     };

  //     // Iterate through the cards and increment the count for each cattype-rarity combination
  //     userPlayingCards.forEach((card) => {
  //       const key = `cattype${card.cattype}_rarity${card.rarity}`;
  //       if (counts[key] !== undefined) {
  //         counts[key] += 1;
  //       }
  //     });

  //     return counts;
  //   }, [userPlayingCards]);

  // Calculate the minimum required per cattype and rarity, and active deck count
  const cardStats = useMemo(() => {
    const totalCards = userPlayingCards.length;
    const deckCount = Math.floor(totalCards / DECK_SIZE) || 1;

    // Initialize an object for combined cattype and rarity counts
    const cattypeRarityCounts: any = {
      cattype0_rarity0: 0,
      cattype0_rarity1: 0,
      cattype0_rarity2: 0,
      cattype1_rarity0: 0,
      cattype1_rarity1: 0,
      cattype1_rarity2: 0,
      cattype2_rarity0: 0,
      cattype2_rarity1: 0,
      cattype2_rarity2: 0,
    };

    // Count each cattype/rarity combination
    userPlayingCards.forEach((card) => {
      const { cattype, rarity } = card;
      const key = `cattype${cattype}_rarity${rarity}`;
      if (cattypeRarityCounts[key] !== undefined) {
        cattypeRarityCounts[key] += 1;
      }
    });

    // Calculate minimum required per cattype/rarity based on deck count
    const minRequiredPerCattypeAndRarity = Object.keys(
      cattypeRarityCounts
    ).reduce((acc: any, key) => {
      acc[key] = deckCount;
      return acc;
    }, {});

    return { cattypeRarityCounts, minRequiredPerCattypeAndRarity, deckCount };
  }, [userPlayingCards]);

  function calculateCreditPoints(cards: any) {
    // Map through the cards array to calculate credit points for each card
    const results = cards.map((card: any) => {
      const creditPoints = (card.rarity + 1) * 100 + (card.strength + 1) * 50;
      return {
        id: card.id,
        name: card.name,
        creditPoints,
      };
    });

    // Calculate the total credit points
    const totalCreditPoints = results.reduce(
      (sum: any, card: any) => sum + card.creditPoints,
      0
    );

    // Return both the individual results and the total
    return {
      cards: results,
      totalCreditPoints,
    };
  }

  console.log(
    "calculateCreditPoints(cards)",
    calculateCreditPoints(selectedCards)
  );
  return (
    <div className="yourCards container">
      <Title title="My Cards" />

      <div className="bigger font here">
        {/* Your Deck is currently Tier:{" "} */}
        {/* <div className="filterIcons">
          {tier === 11 ? (
            <img src={t1} alt="Badge" className="badge" />
          ) : tier === 12 ? (
            <img src={t2} alt="Badge" className="badge" />
          ) : (
            <img src={t3} alt="Badge" className="badge" />
          )}{" "}
        </div> */}
        {/* <br></br>
        <br></br> The Average Strength of your Deck is {avgstrength} */}
      </div>
      <ToggleSwitch
        checked={cardAccess}
        onChange={toggleCardAccess}
        title="CARD ACCESS"
      />
      <ProgressDemo max={500} currentValue={avgstrength} />

      <div className="totalCards">
        {isStorageActive ? storagefilteredData.length : filteredData.length}/
        {isStorageActive ? storagetotal : total}
      </div>

      {/* <div className="selectWallet">
        <div className="wallet">DECK</div>
        <ToggleSwitch checked={isStorageActive} onChange={handleWalletToggle} />
        <div className="wallet">STORAGE</div>
      </div> */}

      <DeckBuilderInfo
        selectedCards={selectedCards}
        allCards={userPlayingCards}
        groupedByRarity={groupedByType}
        isStorageActive={isStorageActive}
        cardStats={cardStats}
      />

      <div className="deckBuilderToggle">
        <div
          className={`${isStorageActive ? "" : "active"} box`}
          onClick={() => handleWalletToggle(false)}
        >
          DECK <BorderStyle />
        </div>
        <div
          className={`${isStorageActive ? "active" : ""} box`}
          onClick={() => handleWalletToggle(true)}
        >
          STORAGE <BorderStyle />
        </div>
      </div>
      <div className="deckBuilderBody">
        {isDeckActive && (
          <div className="selectAllCards">
            <label>
              <input type="checkbox" onChange={handleCheckboxClick} />
              Select all cards(max 80 cards){" "}
            </label>
          </div>
        )}
        <div className="filterContainer">
          <div className="filterCards">
            <div className="filterIcons">
              <div
                onClick={() => handleRarityClick(0)}
                className={selectedRarity === 0 ? "selected_type" : ""}
              >
                <img src={squareIcon} alt="" />
              </div>
              <div
                onClick={() => handleRarityClick(1)}
                className={selectedRarity === 1 ? "selected_type" : ""}
              >
                <img src={triangleIcon} alt="" />
              </div>
              <div
                onClick={() => handleRarityClick(2)}
                className={selectedRarity === 2 ? "selected_type" : ""}
              >
                <img src={circleIcon} alt="" />
              </div>
              <div
                onClick={() => handleRarityClick(3)}
                className={selectedRarity === 3 ? "selected_type" : ""}
              >
                <img src={rectangleIcon} alt="" />
              </div>
              <div
                onClick={() => handleTypeClick(0)}
                className={selectedType === 0 ? "selected_type" : ""}
              >
                <img src={character} alt="" />
              </div>
              <div
                onClick={() => handleTypeClick(1)}
                className={selectedType === 1 ? "selected_type" : ""}
              >
                <img src={weapon} alt="" />
              </div>
              <div
                onClick={() => handleTypeClick(2)}
                className={selectedType === 2 ? "selected_type" : ""}
              >
                <img src={shield} alt="" />
              </div>
            </div>
            <div className="filterIcons"></div>
          </div>
        </div>
        <div className="bulkSelect">
          <ToggleSwitch checked={isDeckActive} onChange={handleToggleChange} />
          <p>BULK SELECT</p>
        </div>

        <div className="container yourCardsList">
          {cardswithActiveFilters?.length &&
            cardswithActiveFilters.map((item, index) => (
              <img
                src={item.image}
                alt={item.image}
                key={index}
                className={`transition-all card hover:-translate-y-2 ${
                  selectedCards.some(
                    (selectedCard) => selectedCard.id === item.id
                  )
                    ? "selected"
                    : ""
                }`}
                onClick={() => {
                  if (isDeckActive) {
                    toggleCardSelection(item);
                  } else {
                    onCardClick(item);
                  }
                }}
              />
            ))}
        </div>
      </div>
      <div className="deckBuilderBtns">
        <div className="btn-groups">
          <PrimaryButton
            text={isStorageActive ? "Return From Storage" : "Send To Storage"}
            onClick={() =>
              isStorageActive ? bulkRetrieveSelected() : bulkStoreSelected()
            }
          />

          <PrimaryButton
            text={isStorageActive ? "Mass Scrap " : "Send External"}
            disabled={!selectedCards?.length}
            onClick={() =>
              isStorageActive
                ? setScrapModalOpen(true)
                : setIsExternalTransferModalOpen(true)
            }
          />
          <Link to="/">
            <PrimaryButton text="Back" />
          </Link>
        </div>
      </div>
      {openModal && selectedCard && (
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <div className="relative modal-body">
            {!transferCard ? (
              <>
                <div className="picoCardBg">
                  <img src={selectedCard.image} alt="" className="mx-auto" />
                </div>
                <p className="cardNumber">#{selectedCard.id.toString()}</p>
                <p className="circulating text-white">
                  {circulatingSupply} Circulating | {burnedSupply} - Burned
                </p>

                <div className="">
                  <PrimaryButton
                    text={`Transfer`}
                    width={`244px`}
                    onClick={() => setTransferCard(true)}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="picoCardBg">
                  <img src={selectedCard.image} alt="" className="mx-auto" />
                </div>
                <div className="transferCard">
                  <label className="">Transfer to</label>
                  <input
                    type="text"
                    className="inputStyle"
                    placeholder="0x(address)"
                    value={toAddress || ""}
                    onChange={handleToAddressChange}
                  />
                </div>
                <div className="actionButtons">
                  <PrimaryButton
                    text={`Confirm`}
                    width={`244px`}
                    onClick={() => transferNft(selectedCard.id)}
                  />
                  <PrimaryButton
                    text={`Cancel`}
                    width={`244px`}
                    onClick={() => setTransferCard(false)}
                  />
                </div>
              </>
            )}
          </div>
          <div className="closeModal" onClick={() => setOpenModal(false)}>
            x
          </div>
        </Modal>
      )}

      {scrapModalOpen && (
        <Modal
          open={scrapModalOpen}
          onClose={() => {
            setToAddress("");
            setIsExternalTransferModalOpen(false);
          }}
        >
          <div className="relative modal-body scrapCards">
            <Title title="Watch out burning cards" />
            <p>
              Scrapping means you will burn all selected cards, you will receive
            </p>
            <span
              className="scrapTotalBtn"
              style={{ backgroundImage: `url(${btnBg})` }}
            >
              {calculateCreditPoints(selectedCards)?.totalCreditPoints}
            </span>
            <p>This action can not be reverted</p>
          </div>
          <div
            className="closeModal"
            onClick={() => {
              setScrapModalOpen(false);
            }}
          >
            x
          </div>
          <div className="actionButtons">
            <PrimaryButton
              text={`SCRAP IT`}
              width={`244px`}
              onClick={() => bulkScrapSelected()}
            />
            <PrimaryButton
              text={`Cancel`}
              width={`244px`}
              onClick={() => {
                setScrapModalOpen(false);
              }}
            />
          </div>
        </Modal>
      )}

      {isExternalTransferModalOpen && (
        <Modal
          open={isExternalTransferModalOpen}
          onClose={() => {
            setToAddress("");
            setIsExternalTransferModalOpen(false);
          }}
        >
          <div className="relative modal-body">
            <div className="transferCard">
              <label className="">Transfer to</label>
              <input
                type="text"
                className="inputStyle"
                placeholder="0x(address)"
                value={toAddress || ""}
                onChange={handleToAddressChange}
              />
            </div>
          </div>
          <div
            className="closeModal"
            onClick={() => {
              setToAddress("");
              setIsExternalTransferModalOpen(false);
            }}
          >
            x
          </div>
          <div className="actionButtons">
            <PrimaryButton
              text={`Confirm`}
              width={`244px`}
              onClick={() => bulkTransferSelected()}
            />
            <PrimaryButton
              text={`Cancel`}
              width={`244px`}
              onClick={() => {
                setToAddress("");
                setIsExternalTransferModalOpen(false);
              }}
            />
          </div>
        </Modal>
      )}
      {mainLoader && <Loader />}
    </div>
  );
};

export default YourCards;
